#lf-hero-ie {
  display: none;
}

_:-ms-input-placeholder, :root {
  & input[type='email'] {
    border: none;
    border-bottom: 1px solid $global-primary-background;
    font-family: $global-font-family;
  }

  & .lf-nav--dropdown-caret {
    display: none !important;
  }

  & .l-body[data-model="home"] {
    .lf-hero-container {
      & .uk-card {
        margin-top: 260px;
      }
    }

    #lf-hero-original {
      display: none;
    }

    #lf-hero-ie {
      display: block;

      .uk-card {
        position: absolute !important;
      }
    }
  }

  .mapboxgl-popup-content {
    overflow: scroll;
    height: 389px;
  }
}

@supports (-ms-ime-align:auto) {
  input[type='email'] {
    border: none;
    border-bottom: 1px solid $global-primary-background;
    font-family: $global-font-family;
  }

  .lf-nav--dropdown-caret {
    display: none !important;
  }

  .l-body[data-model="home"] {
    .lf-hero-container {
      & .uk-card {
        margin-top: 260px;
      }
    }

    #lf-hero-original {
      display: none;
    }

    #lf-hero-ie {
      display: block;

      .uk-card {
        position: absolute !important;
      }
    }
  }
}
