.photo-grid-container {
  border-bottom: 1px solid white;

  @media (min-width: $breakpoint-medium) {
    border-right: 1px solid white;
    &:nth-of-type(even) {
      border-right: none;
    }
  }

  @media (min-width: $breakpoint-large) {
    &:nth-of-type(even) {
      border-right: 1px solid white;
    }

    &:last-of-type {
      border-right: none;
    }
  }

  & > div {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 495px;
      right: 30px;
      bottom: 30px;
      left: 30px;
      border: 1px solid white;
      z-index: 10000;
      pointer-events: none;
      transition-duration: .5s;
    }

    &::before {
      clip-path: polygon(
        0 0,
        0 10px,
        10px 10px,
        10px 0
      );
    }

    &::after {
      clip-path: polygon(
        calc(100% - 10px) calc(100% - 10px),
        calc(100% - 10px) 100%,
        100% 100%,
        100% calc(100% - 10px)
      );
    }

    &:hover {
      background: rgba(255,255,255,.15) !important;

      &::before,
      &::after {
        top: 30px;
      }

      &::before {
        clip-path: polygon(
          0 0, /* top left */
          0 100%, /* top right */
          100% 100%, /* bottom right */
          100% 0 /* bottom left */
        );
      }

      &::after {
        clip-path: polygon(
          0% 0%,
          0% 100%,
          100% 100%,
          100% 0%
        );
      }
    }
  }

  &.photo-grid-card > div {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 15px;
      right: 15px;
      bottom: 150px;
      left: 30px;
      border: 1px solid white;
      z-index: 10000;
      pointer-events: none;
      transition-duration: .5s;
    }

    &::before {
      clip-path: polygon(
        0 0,
        0 10px,
        10px 10px,
        10px 0
      );
    }

    &::after {
      clip-path: polygon(
        calc(100% - 10px) calc(100% - 10px),
        calc(100% - 10px) 100%,
        100% 100%,
        100% calc(100% - 10px)
      );
    }

    &:hover {
      background: rgba(255,255,255,.15) !important;

      &::before,
      &::after {
        top: 15px;
      }

      &::before {
        clip-path: polygon(
          0 0, /* top left */
          0 100%, /* top right */
          100% 100%, /* bottom right */
          100% 0 /* bottom left */
        );
      }

      &::after {
        clip-path: polygon(
          0% 0%,
          0% 100%,
          100% 100%,
          100% 0%
        );
      }
    }
  }
}

.photo-grid-image {
  will-change: filter, transform;
  transition-duration: .25s;
  transform: translate(-50%, -50%) scale(1.1);
  transition-timing-function: linear;
}

.photo-grid-container:hover .photo-grid-image {
  filter: blur(10px);
  transform: translate(-50%, -50%) scale(1.15);
}

.photo-grid-hover {
  cursor: pointer;
  margin: 0 !important;

  &:hover {
    .photo-grid-content {
      max-height: 120px;
      margin-bottom: 60px;
    }

    .photo-grid-hidden-content {
      opacity: 1;
      transition-delay: .2s;
      transition-duration: .5s;
    }
  }

  .photo-grid-content {
    transition-duration: .5s;
    max-height: 3.5em;
    margin-bottom: 40px;

    & > h3 {
      font-weight: 900;
      position: relative;
      padding: .5em 0;
    }
  }

  .photo-grid-hidden-content {
    position: relative;
    transition-duration: .25s;
    opacity: 0;
    min-height: 40px;
    font-size: 13px;
  }
}

.photo-grid-card-content {
  opacity: 0;
  transition-duration: .5s;
}

.photo-grid-container:hover .photo-grid-card-content {
  opacity: 1;
}
