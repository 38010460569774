#map, #lf-map-container > div:first-child {
  height: calc(100vh - 207px);
}

#lf-list-errors > div:first-child, #lf-list-container {
  min-height: calc(100vh - 540px);
}

#map {
  overflow: hidden !important;
}

#lf-map-container {
  @media (max-width: $breakpoint-medium - 1) {
    display: none !important;
  }
}

#lf-properties-toolbar {
  @media (max-width: $breakpoint-medium - 1) {
    #lf-map-toggle {
      display: none;
    }

    select, button {
      margin-bottom: 20px;
    }
  }
}

#lf-map-toggle {
  padding: 0 1em;
  font-weight: bold;
  min-width: 140px;
  margin: 0 10px 0 20px;

  & span {
    position: relative;
    top: -1px;
  }
}

#lf-filter-toggle {
  padding: 1em;
  height: 46px;
  font-weight: bold;
  margin: 0 15px !important;

  & span {
    position: relative;
    top: -2px;
  }
}

#lf-filter-visibility {
  select:first-of-type {
    margin-top: 20px;
  }

  @media (min-width: $breakpoint-medium) {
    display: block !important;

    & > .filters-container {
      margin-top: 0 !important;
    }

    select:first-of-type {
      margin-top: 0;
    }
  }
}

#lf-map-filters-grid {
  & > select {
    font-size: 14px !important;
    color: white;
    padding-left: 10px;
    width: 135px;
    height: 46px;
    margin-right: 10px;

    & * {
      color: $global-color;
    }

    @media (max-width: $breakpoint-small-max) {
      width: 100%;
      margin: 5px 15px;
    }
  }

  & button {
    @media (max-width: $breakpoint-small-max) {
      // width: 100%;
      margin: 5px 15px;
    }
  }

  .lf-clip-path-button:last-of-type {
    margin-right: 20px;
  }
}

#lf-map-container.lf-properties-hidden {
  display: none;
}

#lf-list-container.lf-properties-hidden {
  @media (min-width: $breakpoint-medium) {
    display: none !important;
  }
}

.lf-dropdown {
  padding: 10px;
}

.mapboxgl-popup {
  transition-property: opacity;
  transition-duration: 0.25s;
  opacity: 0;
}

.lf-map-popup {
  width: 370px;
  max-height: 398px;
  opacity: 1;
  font-family: $global-font-family;

  & > .mapboxgl-popup-tip {}

  & > .mapboxgl-popup-content {
    padding: 0;
    box-shadow: 0 5px 15px rgba(0,0,0,0.20);
    overflow-y: scroll;
  }

  & .lf-map-tooltip-results {
    border-bottom: 1px solid #eee;
  }

  & .lf-map-tooltip-content {
    & h5 {
      margin-bottom: 3px;
    }
  }
}

.lf-map-listing-location,
.lf-map-listing-element {
  border: 1px solid #eee;
  border-top: none;
  border-right: 1px solid rgba(0,0,0,0);
  border-left: 1px solid rgba(0,0,0,0);
}

.lf-map-listing-element {
  span {
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  }

  &:not(.uk-section-muted):hover {
    border: 1px solid $global-secondary-background;
    margin-top: -1px;
  }

  &.uk-section-muted:hover {
    cursor: unset;
  }
}

.lf-map-listing-image {
  height: 64px;
  width: 70px;
}

.lf-map-listing-details {
  padding-left: 20px;
  & span {
    letter-spacing: .5px;
    font-size: 11px;
  }
}

.lf-map-listing-detail-span {
  letter-spacing: .5px;
  font-size: 11px;
}

.lf-listing-card {
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}

.lf-listing-grid {
}

h5.uk-text-bold {
  margin-bottom: 4px;
  margin-top: 0;
}

.lf-map-filter-controls-container {
  @media (min-width: $breakpoint-medium) {
    width: auto;
  }
}

.lf-map-filter-controls {
  @media (max-width: $breakpoint-small-max) {
    justify-content: space-between;
  }
  a, button {
    @media (min-width: $breakpoint-medium) {
      margin-right: 10px !important;
    }
  }
  button {
    @media (max-width: $breakpoint-small-max) {
      margin-right: 15px !important;
    }
  }
}
