.lf-property-information {
  @media (min-width: $breakpoint-small) {
    padding-bottom: 140px;
  }
}

.lf-property-description-main {
  ul {
    padding-right: 40px;
  }

  @media (min-width: $breakpoint-small) {
    padding-right: 60px;

    ul {
      padding-right: 0;
    }
  }
}

.lf-feature-list {
  & ul {
    list-style-type: none;
  }
  & ul li::before {
    content: "-";
    position: absolute;
    margin-left: -12px;
  }
}

.lf-property-description-sidebar {
}

.lf-detail-address {
  position: static;
  z-index: 10;
  left: 0;
  right: 0;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  background-color: $global-secondary-dark-background;

  & > p {
    padding: 0 30px;
  }

  & > a {
    &.uk-button.uk-text-bold:hover {
      color: $global-color !important;
    }
  }

  @media (min-width: $breakpoint-small) {
    position: absolute;
    margin-top: -70px;
    width: 400px;
    padding-left: auto;
    padding-right: auto;
  }

  @media (min-width: $breakpoint-large) {
    margin-top: -80px;
  }
}

.lf-responsive-slideshow-container {
  @media (min-width: $breakpoint-medium) {
    height: 100% !important;
  }
}

.lf-responsive-slideshow-image {
  @media (min-width: $breakpoint-medium) {
    height: 100% !important;
    width: auto !important;
  }
}

.lf-responsive-slideshow-image-expand {
  margin: 20px;
  padding: 10px;
  background-color: white;
  width: 10px;
  height: 10px;
  border-radius: 500px;

  & > span {
    position: absolute;
    margin-top: -15px;
    margin-left: -2px;
    font-size: 23px;
    user-select: none;
  }
}

.lf-details-table {
  td {
    padding: 0 10px 0 0;
    min-width: 140px;

    &:nth-of-type(2) {
      vertical-align: bottom;
    }
  }
}

.lf-custom-table {
  & th {
    font-size: 12px;
    color: #20273c;
  }
}

.lf-video {
  position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;

  & iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
