.uk-dotnav > * > * {
  width: 16px;
  height: 16px;
}

.uk-dotnav > .uk-active > * {
  background-color: $global-primary-background;
}

.uk-dotnav > * > :hover {
  background-color: rgba(28, 41, 89, .3);
}

.lf-slideshow-nav-arrows {
  color: white !important;
  padding: 20px;
}

.lf-slideshow-nav-light {
  padding: 20px;
  & > * {
    padding-left: 6px;
  }
  & li a {
    border: 2px solid white;
  }
  & li *:hover, & li *:active, & li *:focus {
    background-color: white;
  }
  & .uk-active > * {
    background-color: white;
  }
}
