.lf-clip-path-button {
  all: unset;
  border: none;
  padding: 1em 2em;
  color: white;
  position: relative;
  background: rgba(255,255,255,0) !important;
  transition-duration: .5s;
  text-transform: uppercase;
  font-size: $global-xsmall-font-size;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border: 1px solid white;
    transition: clip-path .25s;
  }

  &:focus {
    outline: none;
  }

  &.uk-button-primary {
    background: rgba(13, 28, 60, 0) !important;
    color: $global-primary-background;
    &:hover {
      background: rgba(13, 28, 60, .15) !important;
      background: none;
    }
    &::before, &::after {
      border: 1px solid $global-primary-background;
    }
  }

  &:hover {
    background: rgba(255,255,255,.15) !important;

    &::before {
      clip-path: polygon(
        0 0, /* top left */
        0 100%, /* top right */
        100% 100%, /* bottom right */
        100% 0 /* bottom left */
      );
    }

    &::after {
      clip-path: polygon(
        0% 0%,
        0% 100%,
        100% 100%,
        100% 0%
      );
    }
  }

  &::before {
    clip-path: polygon(
      0 0,
      0 10px,
      10px 10px,
      10px 0
    );
  }

  &::after {
    clip-path: polygon(
      calc(100% - 10px) calc(100% - 10px),
      calc(100% - 10px) 100%,
      100% 100%,
      100% calc(100% - 10px)
    );
  }
}

.lf-tag {
  border: 1px solid $global-primary-background;
  padding: 5px;
  position: relative;
  background: rgba(13, 28, 60, .15) !important;
  color: $global-primary-background;
  transition-duration: .5s;
  text-transform: uppercase;
  font-size: $global-xsmall-font-size;
}

input[type='email'] {
  background: unset;
  border: unset;
  font-family: unset;

  padding: 1em 0;
  margin-right: 1em;
  position: relative;
  transition-duration: .5s;
  text-transform: uppercase;
  font-size: $global-xsmall-font-size;
  color: $global-primary-background;
  border-bottom: 1px solid $global-primary-background;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $global-primary-background;
  }
}
