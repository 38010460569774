//
// Component:       Global Variables
// Description:     Defines common values which are used across all components
//
// ========================================================================

// Note: We're trying to figure out a better system to handle all the
// global uikit variables but for now, try to include only those that you need.
// If you do change one, make it a habit of removing the `!default` flag so we
// know for sure that it's been modified.

// Breakpoints
// ========================================================================

// Phone Portrait:   Galaxy (360x640), iPhone 6 (375x667), iPhone 6+ (414x736)
// Phone Landscape:  Galaxy (640x360), iPhone 6 (667x375), iPhone 6+ (736x414)
// Tablet Portrait:  iPad (768x1024), Galaxy Tab (800x1280),
// Tablet Landscape: iPad (1024x768), iPad Pro (1024x1366),
// Desktop:          Galaxy Tab (1280x800), iPad Pro (1366x1024)

$breakpoint-small:                              667px;  // Phone landscape
$breakpoint-medium:                             960px !default;  // Tablet Landscape
$breakpoint-large:                              1200px !default; // Desktop
$breakpoint-xlarge:                             1600px !default; // Large Screens

$breakpoint-xsmall-max:                         ($breakpoint-small - 1) !default;
$breakpoint-small-max:                          ($breakpoint-medium - 1) !default;
$breakpoint-medium-max:                         ($breakpoint-large - 1) !default;
$breakpoint-large-max:                          ($breakpoint-xlarge - 1) !default;


// Global variables
// ========================================================================

//
// Typography
//

@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham/Gotham-Light.otf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham/Gotham-Bold.otf");
  font-weight: bold;
  font-style: normal;
}


$global-font-family:                             "Gotham", BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$global-font-size:                               16px !default;
$global-line-height:                             1.75;

$global-xxlarge-font-size:                       2.625rem !default; // 42px
$global-xlarge-font-size:                        2rem !default;     // 32px
$global-large-font-size:                         1.5rem !default;   // 24px
$global-medium-font-size:                        1.25rem !default;  // 20px
$global-small-font-size:                         0.875rem !default; // 14px
$global-xsmall-font-size:                        0.75rem;           // 12px

//
// Colors
//

$global-color:                                   #20273C;
$global-emphasis-color:                          #1C2D6C;
$global-muted-color:                             #aca9bf !default;

$global-link-color:                              #1C2D6C;
$global-link-hover-color:                        #0C1C3C;

$global-inverse-color:                           #fff !default;

//
// Backgrounds
//

$global-background:                              #fff !default;

$global-muted-background:                        #f3f3f3 !default;
$global-primary-background:                      #1c2959;
$global-secondary-background:                    #0C1C3C;
$global-secondary-dark-background:               #0A1834;

$global-success-background:                      #32d296 !default;
$global-warning-background:                      #faa05a !default;
$global-danger-background:                       #f0506e !default;

//
// Borders
//

$global-border-width:                            1px !default;
$global-border:                                  #e5e5e5 !default;

//
// Box-Shadows
//

$global-small-box-shadow:                        0 2px 8px rgba(0,0,0,0.08) !default;
$global-medium-box-shadow:                       0 5px 15px rgba(0,0,0,0.08) !default;
$global-large-box-shadow:                        0 14px 25px rgba(0,0,0,0.16) !default;
$global-xlarge-box-shadow:                       0 28px 50px rgba(0,0,0,0.16) !default;

//
// Spacings
//

// Used in margin, section, list
$global-margin:                                  20px !default;
$global-small-margin:                            10px !default;
$global-medium-margin:                           40px !default;
$global-large-margin:                            70px !default;
$global-xlarge-margin:                           140px !default;

// Used in grid, column, container, align, card, padding
$global-gutter:                                  30px !default;
$global-small-gutter:                            15px !default;
$global-medium-gutter:                           40px !default;
$global-large-gutter:                            70px !default;

//
// Controls
//

$global-control-height:                          40px !default;
$global-control-small-height:                    30px !default;
$global-control-large-height:                    55px !default;

//
// Z-index
//

$global-z-index:                                 1000 !default;
