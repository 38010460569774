:root {
  background: $global-secondary-dark-background;
}

p:empty {
  display: none;
}

.uk-container-small {
  max-width: 1000px;
}

.uk-container-xsmall {
  max-width: 690px;
}

.lf-section-background-image {
  user-select: none;
  pointer-events: none;
  opacity: .75;
}

.uk-section-default .lf-section-background-image {
  opacity: .3;
}

.uk-height-xlarge {
  height: 600px;
}

.uk-height-xxlarge {
  height: 700px;
}

.uk-icon {
  cursor: default;
}

body[data-model="home"] .uk-offcanvas-content {
  & > div:not(.lf-hero, .lf-nav) {
    position: relative;
    z-index: 100;
  }
}

.lf-region-header:first-of-type {
  margin-top: 0px !important;
}

.uk-offcanvas-close {
  cursor: pointer;
  padding: 10px;
}

.lf-stat {
  font-size: 4em;
}

.uk-button {
  line-height: 1.25;
  padding: 10px 30px;
}

h1 {
  @media (max-width: $breakpoint-small-max) {
    font-size: 32px;
  }
}

h2 {
  @media (max-width: $breakpoint-small-max) {
    font-size: 26px;
  }
}

.lf-bio-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 40px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-content {
    overflow-y: auto;
  }
}

.lf-modal-close {
  background: transparent;

  & svg {
    color: white;
    width: 30px;
    height: 30px;
    padding: 10px;
  }
}

.lf-image-modal-image {
  @media (min-width: 999px) {
    max-width: 1000px;
  }
}

.lf-cta-content-container {
  padding-left: 15px;
  padding-right: 15px;

  @media (min-width: $breakpoint-small) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (min-width: $breakpoint-large) {
    padding-left: 70px;
    padding-right: 70px;
  }
}

.lf-cta img.full-width {
  display: block;
  margin: auto;
}

.iframe-container {
  position: relative;
  height: 1288px;
  @media (min-width: 506px) {
    height: 1245px;
  }
  @media (min-width: 511px) {
    height: 1386px;
  }
  overflow: hidden;

  iframe {
    position: absolute;
    top: -156px;
    @media (min-width: 506px) {
      top: -98px;
    }
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.lf-property-banner-container {
  overflow: hidden;
}

.lf-property-banner {
  position: absolute;
  z-index: 1;
  position: absolute;
  z-index: 1;
  font-size: 14px;
  padding: 15px 20px;
  line-height: 1.4;
  font-weight: bold;
  text-align: center;
  transform-origin: top right;
  color: white;
  background: $global-color;

  &.red {
    color: white;
    background: #e0112e;
  }
  &.white {
    color: inherit;
    background: white;
  }
}
