.pagination {
  a, span {
    border: 1px solid $global-primary-background;
    margin: 5px;
    position: relative;
    background: rgba(13, 28, 60, .15) !important;
    color: $global-primary-background;
    transition-duration: .5s;
    text-transform: uppercase;
    font-size: $global-xsmall-font-size;
    display: inline-flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
  }

  a {
    background: rgba(13, 28, 60, .1) !important;
    border: 1px solid lighten($global-primary-background, 10%);
    color: lighten($global-primary-background, 10%);
  }
}

.lf-newspost-content {
  @media (max-width: $breakpoint-small-max) {
    padding: 30px 0 !important;
  }
}
