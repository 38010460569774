.richtext {
  h3, h4, h5 {
    color: lighten($global-color, 25%);
  }

  ul li {
    list-style: none;
    border-bottom: 1px solid #eee;
    padding: 8px 0;

    &:before {
      content: "• ";
    }
  }

  a:not(.uk-button), a:not(.uk-button):hover {
    text-decoration: underline !important;
  }
}

.richtext--detail {
  h3, h4, h5 {
    color: lighten($global-color, 25%);
  }

  a:not(.uk-button), a:not(.uk-button):hover {
    text-decoration: underline !important;
  }
}

.lf-blockquote {
  padding-left: 40px;

  & div {
    color: lighten($global-color, 25%);
  }

  & > div {
    padding-left: 30px;
    border-left: 2px solid $global-primary-background;
  }

  & > div > div:first-child {
    font-style: italic;
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
}
