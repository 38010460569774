//
// Component:       Base
//
// ========================================================================

// Here's an example of adding a border to all headings
// @mixin hook-base-heading() {
//   border-bottom: 1px solid $global-color;
// }

@mixin hook-base-link-hover() {
  text-decoration: none !important;
}

@mixin hook-base-heading() {
  text-transform: uppercase;
}

@mixin hook-base-h6() {
  color: #4B4B4B;
  font-weight: bold;
  font-size: $global-xsmall-font-size;
  letter-spacing: 2px;
}

.uk-text-xsmall {
  font-size: $global-xsmall-font-size;
}

.uk-light, .uk-section-primary:not(.uk-preserve-color), .uk-section-secondary:not(.uk-preserve-color), .uk-tile-primary:not(.uk-preserve-color), .uk-tile-secondary:not(.uk-preserve-color), .uk-card-primary.uk-card-body, .uk-card-primary > :not([class*='uk-card-media']), .uk-card-secondary.uk-card-body, .uk-card-secondary > :not([class*='uk-card-media']), .uk-overlay-primary, .uk-offcanvas-bar {
  color: white !important;

  & a:hover {
    transition-duration: .25s;
    color: rgba(255,255,255,.6) !important;
  }
}
