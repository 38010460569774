.lf-hero-container {
  position: relative;
  top: -($nav-height-inactive * 2); // Tied to y parallax offset in html.
  margin-bottom: -($nav-height-inactive * 2); // Compensate for above.
  height: 380px;
  transform-style: preserve-3d;
}

.lf-hero-image-1, .lf-hero-image-2 {
  opacity: 1;
  background-size: cover;
}

.lf-hero-image-1 {
  z-index: 3;

  @media (min-width: $breakpoint-medium) {
    clip-path: polygon(0% 0%, 66% 0%, 33% 100%, 0% 100%);
  }
}

.lf-hero-image-2 {
  z-index: 2;
  opacity: 1;

  // @media (min-width: $breakpoint-medium) {
  //   clip-path: polygon(66% 0%, 100% 0%, 100% 100%, 33% 100%);
  // }
}

.lf-hero-container-inner {
  position: relative;
  top: -($nav-height-inactive * 2); // Tied to y parallax offset in html.
  margin-bottom: -($nav-height-inactive * 2); // Compensate for above.
  transform-style: preserve-3d;

  @media (max-width: $breakpoint-small-max) {
    height: 150px;
  }
}

.lf-hero-container-border {
  border-bottom: 1px solid $global-secondary-background;
}

.lf-hero-inner-page-title {
  margin-left: 65px;
  margin-bottom: 55px;

  @media (max-width: $breakpoint-small-max) {
    margin: 20px 15px !important;
  }
}

.lf-hero-container {
  & .uk-card {
    pointer-events: none;

    & * {
      pointer-events: all;
    }
  }
}
