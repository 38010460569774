$nav-mobile-height: 80px;
$nav-height: 100px;
$nav-height-inactive: 120px;

body[data-model='home'] {
  .lf-nav {
    z-index: 1000 !important;
  }
}

.lf-nav:not(.uk-active) {
  color: white;
  height: $nav-height-inactive;

  .lf-nav--grid {
    height: $nav-height-inactive;
    display: flex;
    align-items: center;
  }

  .lf-nav--icon {
    background-image: url("../images/brand-full.svg");
    width: 95px;
    margin-right: 0;
  }

  .lf-nav--number-wrapper {
    display: none;

    & > span {
      // background-image: url("../images/telephone-white.svg");
    }
  }

  a {
    color: white;
    &:hover {
      color: rgba(255,255,255,.8);
    }
  }
}

.lf-nav--dropdown {
  text-align: center;
  background: #fff;
  border-radius: 2px;
  filter: drop-shadow(0px 0px 5px rgba(0,0,0,0.25));

  & > a {
    color: $global-link-color !important;
    display: block;
    font-weight: normal !important;
    margin-left: 0 !important;
    text-transform: none !important;
    line-height: 2;

    &:hover {
      color: $global-link-hover-color !important;
    }
  }

  & .lf-nav--dropdown-caret {
    display: block;
    background: #fff;
    width: 20px;
    height: 10px;
    position: absolute;
    top: -9px;
    left: calc(50% - 10px);
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
  }
}

.lf-nav {
  height: $nav-mobile-height;
  @media (min-width: $breakpoint-medium) {
    height: $nav-height;
  }
  z-index: 1000;

  .lf-nav--icon {
    width: 75px;
    height: 55px;
    background-image: url("../images/brand-short.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 10px;
  }

  .lf-nav--grid {
    height: $nav-mobile-height;
    @media (min-width: $breakpoint-medium) {
      height: $nav-height;
    }
    display: flex;
    align-items: center;
  }

  .lf-nav--number-wrapper > span {
    background-image: url("../images/telephone.svg");
  }

  a:not(.lf-nav--icon--link):not(.lf-nav--util--link) {
    font-weight: bold;
    font-size: $global-small-font-size;
    text-transform: uppercase;
    margin-left: 20px;
  }
}

.lf-nav--util--link {
  @extend .uk-text-xsmall;
  @extend .uk-text-uppercase;
  font-weight: 200;
}

.lf-nav.uk-active {
  background: white;
  border-bottom: 1px solid rgba(13, 28, 60, 0.1);
}

.lf-footer-nav {
  @media (min-width: $breakpoint-small) {
    margin-top: 0 !important;
  }
}

.lf-footer-background-image {
  background-image: url("../images/brand-short-light.svg");
  background-position: calc(100% - 80px) center;
  background-size: 335px;
  background-repeat: no-repeat;
}


.lf-footer-menu-link {
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}

.lf-util-menu > div:first-child {
  margin-bottom: 2px;
}
